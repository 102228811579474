import { Helmet } from 'react-helmet-async';
import React from 'react';
import appProp from 'utils/proptypes/application';
import { injectModels } from 'state';

// This is placed where we use the search overlay or something similar, NOT the search pege itself
// Ex imports in FrontPage views. No need to pass the application down here.
const SiteSearchStructuredData = ({ application }) => {
    // Setup the url
    const protocol = application.protocol ? `${application.protocol}://` : '';
    const domain = application.domain || '';
    const localePath = application.path || '';
    const baseUrl = protocol + domain;
    const searchPath = application.config.options.specified_pages.search_results?.slug || null;

    const sitelinksSearchbox = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        'url': baseUrl,
        'potentialAction': {
            '@type': 'SearchAction',
            'target': `${baseUrl}${localePath}${searchPath}?s={search_term_string}`,
            'query-input': 'required name=search_term_string',
        },
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(sitelinksSearchbox, null, 2)}</script>
        </Helmet>
    );
};

SiteSearchStructuredData.propTypes = {
    application: appProp.isRequired,
};

export default injectModels(['application'])(SiteSearchStructuredData);
